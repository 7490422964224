var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",attrs:{"id":"app-mobile"}},_vm._l((_vm.list),function(item,ind){return _c('div',{key:ind},[_c('div',{staticClass:"boxContent"},[_vm._m(0,true),_c('div',{staticClass:"content"},[_c('div',{staticClass:"contentFont"},[_vm._v(" 您有一封待评价问卷《"+_vm._s(item.title)+"》(ID:"+_vm._s(item.questionnaireId)+")请查收 ")]),_c('div',{staticClass:"contentBtn"},[(
              item.canAnswer === true && (item.answered === null ||
              item.answered <= 0)
            )?_c('van-button',{staticClass:"btnLeft",on:{"click":function($event){return _vm.$router.push(`/mobile/questionTitle/${item.uid}`)}}},[_vm._v("开始答题")]):_vm._e(),(item.canAnswer !== true)?_c('van-button',{staticClass:"btnLeftDisabled",attrs:{"disabled":""}},[_vm._v("开始答题")]):_vm._e(),(
              item.canAnswer === true &&
              item.answered !== null &&
              item.answered > 0
            )?_c('van-button',{staticClass:"btnLeftUnDis",on:{"click":function($event){return _vm.$router.push(`/mobile/questionTitle/${item.uid}`)}}},[_vm._v("开始答题")]):_vm._e()],1)])])])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img",attrs:{"src":require("../../../assets/雷士上下组合-SVG_画板 1.png"),"alt":""}})])
}]

export { render, staticRenderFns }
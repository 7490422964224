<template>
  <div
    id="app-mobile"
    class="box"
  >
    <div
      v-for="(item, ind) in list"
      :key="ind"
    >
      <div class="boxContent">
        <div>
          <img
            class="img"
            src="../../../assets/雷士上下组合-SVG_画板 1.png"
            alt=""
          />
        </div>
        <div class="content">
          <div class="contentFont">
            您有一封待评价问卷《{{ item.title }}》(ID:{{
              item.questionnaireId
            }})请查收
          </div>
          <div class="contentBtn">
            <van-button
              class="btnLeft"
              @click="$router.push(`/mobile/questionTitle/${item.uid}`)"
              v-if="
                item.canAnswer === true && (item.answered === null ||
                item.answered <= 0)
              "
            >开始答题</van-button>
            <van-button
              class="btnLeftDisabled"
              v-if="item.canAnswer !== true"
              disabled
            >开始答题</van-button>
            <van-button
              class="btnLeftUnDis"
              @click="$router.push(`/mobile/questionTitle/${item.uid}`)"
              v-if="
                item.canAnswer === true &&
                item.answered !== null &&
                item.answered > 0
              "
            >开始答题</van-button>
            <!-- <van-button
              v-if="
                item.canAnswer !== true && item.answered !== null &&
                item.answered > 0
              "
              class="btnRight"
              @click="statistics(item)"
            >
              结果统计
            </van-button>
            <van-button
              v-if="item.answered === null || item.answered === 0"
              class="btnRightDisabled"
              disabled
            >结果统计</van-button>
            <van-button
              v-if="
                item.canAnswer === true &&
                item.answered !== null &&
                item.answered > 0
              "
              class="btnRightUnDis"
              @click="statistics(item)"
            >结果统计</van-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { respondentList } from "@/apis/mobileApi";
import { getToken } from "@/utils/cookie";
export default {
  name: "home",
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      respondentList({ username: getToken() }).then(({ data }) => {
        if (data) {
          this.list = data;
        } else {
          this.list = [];
        }
      });
    },
    statistics(data) {
      this.$router.push({
        name: 'mobile-statistics',
        query: {
          id: data.questionnaireId
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
$room: 1020 / 375;
.box {
  // width: 100vw;
  height: calc(100vh - 50px * $room);
  background: #f6f5fa;
  padding-top: 10px * $room;
}
.boxContent {
  padding-left: 10px * $room;
  margin-top: 10px * $room;
  display: flex;
}
.img {
  width: 40px * $room;
  height: 40px * $room;
  border-radius: 5px * $room;
}
.content {
  width: 250px * $room;
  border-radius: 5px * $room;
  background: #fff;
  margin-left: 10px * $room;
  .contentFont {
    font-size: 15px * $room;
    margin: 10px * $room 10px * $room 0 10px * $room;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px * $room;
    line-height: 23px * $room;
  }
  .contentBtn {
    padding: 15px * $room 15px * $room;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btnLeft {
      font-size: 14px * $room;
      padding: 14px * $room;
      border-radius: 50px * $room;
      background: #3a8557;
      color: #fff;
    }
    .btnLeftDisabled {
      background: none;
      border: 1px solid #ccc;
      color: #ccc;
      font-size: 14px * $room;
      padding: 14px * $room;
      border-radius: 50px * $room;
    }
    .btnLeftUnDis {
      background: none;
      border: 1px solid #008755;
      color: #008755;
      font-size: 14px * $room;
      padding: 14px * $room;
      border-radius: 50px * $room;
    }
    .btnRight {
      font-size: 14px * $room;
      padding: 14px * $room;
      border-radius: 50px * $room;
      background: #3a8557;
      color: #fff;
    }
    .btnRightDisabled {
      background: none;
      border: 1px solid #ccc;
      color: #ccc;
      font-size: 14px * $room;
      padding: 14px * $room;
      border-radius: 50px * $room;
    }
    .btnRightUnDis {
      background: none;
      border: 1px solid #008755;
      color: #008755;
      font-size: 14px * $room;
      padding: 14px * $room;
      border-radius: 50px * $room;
    }
  }
}
</style>
